@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, h1, h2, h3, h4, h5, h6,
button, input, textarea, ul, li,
a, div, p, span, th, tr, td {
  font-family: "Inter" !important;
}

button, a {
  text-transform: capitalize !important;
}

.gutter.gutter-horizontal {
  border-left: 1px solid #D6DDE5;
  margin-left: 7px;
  cursor: ew-resize;

  &:before {
    content: "";
    display: block;
    height: 100%;
    width: 15px;
    margin-left: -8px;
    background-image: url("../icons/gutterIcon.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.selected-call-item {
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    margin-top: -17px;
    margin-left: -21px;
    height: 100%;
    width: 7px;
    background-color: #1A73E8;
  }
}

.selected-highlight-item {
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    margin-top: -17px;
    margin-left: -21px;
    height: 100%;
    width: 7px;
    background-color: #1A73E8;
  }
}

.grouped-call-box {
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 18px;
    left: 5px;
    height: calc(100% - 30px);
    width: 1px;
    border-right: 1px dashed #D6DDE5;
  }
}

.date-range-picker {

  .MuiTextField-root {
    width: 100%;
  }
}

.date-range-filter-section {

  .MuiTextField-root {
    width: 100%;

    .MuiOutlinedInput-root {
      padding: 0;

      .MuiOutlinedInput-input {
        height: 36px;
        padding: 0;
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }
}

.Toastify {

  .Toastify__toast-container {
    max-width: 100vw;
    z-index: 1060;

    .Toastify__toast {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #253862;
      color: #FFFFFF;
      border-radius: 12px;
      border: 1px solid #253862;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      padding: 8px 16px;

      .Toastify__toast-body {
        margin-right: 20px;

        .Toastify__toast-icon {
          color: #23A566;
        }
      }

      .Toastify__close-button {
        align-self: center;
        color: #FFFFFF;
      }
    }
  }

  @media (max-width: 992px) {
    .Toastify__toast-container {
      padding: 12px;
    }
  }
}

.scrolledNotes {
  height: 300px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  };
}
